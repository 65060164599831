<template>
    <section >

    </section>
</template>

<script>
    // import AuthService from "@/services/auth.helper"

    export default {
        data() {
            return {
            }
        },
        props: {
            student: {}
        },
        mounted() {
        },
        methods: {

        }
    }
</script>


